import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Player } from '@lottiefiles/react-lottie-player';

const endpoint = process.env.REACT_APP_ENDPOINT;
const authHeader = {
  'Content-Type': 'application/json',
  'X-API-KEY': 'fwefsd34q'
}

const DesignInterface = () => {
  const [inputValue, setInputValue] = useState('')
  const [image, setImage] = useState([])
  const [loading, setLoading] = useState(false)
  const [ideas, setIdeas] = useState("")
  const [selectedModel, setModel] = useState("nightvision-xl-0791")
  

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handlePromptChange = (event, index) => {
    const newImages = [...image];
    newImages[index] = { ...newImages[index], prompt: event.target.value };
    setImage(newImages);
  };

  const handleRegenerate = async (event, index) => {
    // Get the prompt of the image to regenerate
    const loadingImg = "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/ajax-loader.gif";
    const loadingImages = [...image];
    loadingImages[index] = { ...loadingImages[index], imageUrl: loadingImg };
    setImage(loadingImages)
    const prompt = image[index].prompt;
    console.log("Regenerating image with prompt: " + prompt);
    // Call the API to regenerate the image
    const response = await fetch(`${endpoint}/job_queue/create_single_job_queue`, {
      method: 'POST',
      headers: authHeader,
      body: JSON.stringify({
        model_id: selectedModel,
        prompt: prompt
      })
    });
    // Get the job ID and fetch the job status every 20 seconds
    if (response.ok) {
      const data = await response.json();
      const jobId = data.tracking_id;
      const intervalId = setInterval(async () => {
        const statusResponse = await fetch(`${endpoint}/job_queue/job_status/${jobId}`, {
          headers: authHeader
        });
        
        if (statusResponse.ok) {
          const statusData = await statusResponse.json();
          console.log(statusData);
          if (statusData.status === 'success') {
            console.log("Image regenerated successfully");
            console.log(statusData);
            const newImages = [...image];
            newImages[index] = { ...newImages[index], imageUrl: statusData.image_url };
            setImage(newImages);
            clearInterval(intervalId);
          }
        }
      }, 20000);
    }
  };

  const handleModelChange = (event) => {
    console.log("Model changed to " + event.target.value)
    setModel(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      setImage([]);
      setIdeas("");

      const response = await fetch(`${endpoint}/job_queue/create_job_queue`, {
        method: 'POST',
        headers: authHeader,
        body: JSON.stringify({
          prompt: inputValue,
          model_id: selectedModel
        })
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        const queue_id = data.tracking_id;
        console.log(queue_id);

        const checkDesignStatus = async () => {
          try {
            const statusResponse = await fetch(
              `${endpoint}/job_queue/design_status/${queue_id}`,
              {
                headers: authHeader});
            if (statusResponse.ok) {
              const statusData = await statusResponse.json();
              console.log(statusData);
              if (statusData.status === 'success') {
                // Design status is success, do something

                let designDescription = statusData.list_of_images.description
                console.log("Design description: " + designDescription)
                setIdeas(designDescription);

                clearInterval(intervalId); // Stop checking the status
                let designs = statusData.list_of_images.designs;

                // setRenderResult(designs);
                console.log("list of designs")
                console.log(designs);
      
                for (let design of designs) 
                {
                  let intervalId2 = null;
                  const checkStatus = async () => {
                    const response = await fetch(`${endpoint}/job_queue/job_status/${design.job_id}`, {
                      headers: authHeader
                    });
                    const datar = await response.json();
                    //let images = image;
                    if (datar.status === 'success') {
                      const imgCheck = {imageUrl: datar.image_url, imageID: design.job_id, prompt: design.prompt};
                      console.log("Checking to add image")
                      console.log(imgCheck);
                      console.log("Current image list")
                      console.log(image);
                      if (imgCheck.imageUrl !== undefined && design.prompt !== undefined)
                        if (!image.includes(imgCheck)) {
                          // images.push({imageUrl: design.imageUrl, imageID: design.job_id, prompt: design.prompt});
                          setImage(prevImages => [...prevImages, imgCheck]);
                          console.log("Image added")
                        }
                      setLoading(false);
                      clearInterval(intervalId2);
                    }
                  };
                  intervalId2 = setInterval(checkStatus, 5000);
                }
              }
            } else {
              console.error('Failed to fetch design status');
            }
          } catch (error) {
            console.error('Error:', error);
          }
        };

        const intervalId = setInterval(checkDesignStatus, 30000); // Call checkDesignStatus every 5 seconds
        
        // console.log(generatedImages);
        // if (generatedImages.length > 0) {
        //   setImage(generatedImages[0]);
        //   setLoading(false);
        // }
      } else {
        console.error('Failed to fetch generated images');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <header className="text-center mb-4">
        <h1>Design made easy.</h1>
        <p className="lead">"Explore DHMUAE's first automated AI mood designer."</p>
      </header>
      <form onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Type in your idea or leave it blank"
          />
            <select className="form-select mt-3" value={selectedModel} onChange={handleModelChange}>
              <option value="nightvision-xl-0791">YITEC-SD-MEDY Model</option>
              <option value="juggernaut-xl-v5">YITEC-SD-JELANI Model</option>
              <option value="architectureexterior">YITEC-SD-SON Model</option>
              <option value="sdlifearch">YITEC-SD-LIFE Test Model</option>
              <option value="dvarch">YITEC-SD-DV Test Model</option>
              <option value="xsmerge-realisticvisionv3">YITEC-SD-RV Test Model</option>
              <option value="xl_7KPc8COgXIFx2VSuEw9p6fsdJ">YITEC-DHM Model (beta)</option>
              {/* Add more options as needed */}
            </select>
        </div>
        <button type="submit" className="btn btn-dark">Submit</button>
      </form>
      <div>
          <ReactMarkdown>{ideas ? ideas : ""}</ReactMarkdown>
        </div>
      <div className="image-placeholder" style={{ minHeight: '300px', maxWidth: '512px' }}>
       
                {/* Image will be displayed here once generated */}
                {loading && <Player
                    autoplay
                    loop
                    src="https://lottie.host/5fdf6f31-8057-4b00-8ea3-3ddcedbc636c/vjECkZXs1Y.json"
                    style={{ height: '300px', width: '300px' }}
                  >
                  
                </Player> }
                  {loading && <p>Medy is doing his work, this could take mintues, please wait...</p>}
        
        {image.map((img, index) => (
          <div>
            <img key={index} src={img.imageUrl} alt="" />
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  value={img.prompt}
                  onChange={event => handlePromptChange(event, index)}
                  
                />
              </div>
              <div className="col-auto">
                <button onClick={event => handleRegenerate(event, index)} className="btn btn-dark" >
                  Regenrate
                </button>
              </div>
            </div>
          </div>
          
        ))}

        {image.length < 4 && image.length > 0 && <p>Images are being generated. Please wait...</p>}

      </div>
          
      </div>
  );
};

export default DesignInterface;