import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure Bootstrap CSS is imported
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from './Header';
import DesignInterface from './Design';

const App = () => {
  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header />
        <Routes style={{ flex: 1 }}>
          <Route path="/" element={<DesignInterface />} />    
        </Routes>
        <footer className="bg-dark text-white text-center py-3" style={{ marginTop: 'auto' }}>
          &copy; Design Haus Medy. Researched by YITEC CO LTD
        </footer>
      </div>
    </Router>
  );
};

export default App;